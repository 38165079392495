import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'src/context/AuthProvider';
import _ from 'lodash';
import DialogTitle from './DialogTitle';
import PropTypes from 'prop-types';
import { signOut } from 'next-auth/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  Modal,
  DialogContent,
  Divider,
  Paper,
  Typography,
  Button,
  OutlinedInput,
  FormControl,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { validateEmail } from 'src/helpers/inputValidation';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Error } from 'src/components/Banners';
import { loadEmails, loadPhones, sendEmailConfirmation } from 'src/api/payerApi';
import { loadError } from 'src/helpers/defaultErrorMessages';
import pageHeaderLoaderStyles from 'styles/pageHeaderLoader.module.css';
import { checkEvent } from 'src/helpers/eventHelper';
import { createEmail, createPhone } from 'src/api/payerApi';
import { removeWhitespacesAndDashes } from 'src/helpers/inputValidation';
import { updateDigitalCollection } from 'src/api/payerApi';

export default function TermsOfServiceDialog({ handleClose, isOpen, isTermsAccepted, setIsTermsAccepted }) {
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const [error, setError] = useState(null);
  const [areEmailsLoading, setAreEmailsLoading] = useState(true);
  const [arePhonesLoading, setArePhonesLoading] = useState(true);
  const [emails, setEmails] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(0);
  const [emailWarningText, setEmailWarningText] = useState(undefined);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState(0);
  const [phones, setPhones] = useState(null);
  const [phone, setPhone] = useState({
    dialCode: '',
    number: '',
    full: '',
  });
  const [isDigitalConsentChecked, setIsDigitalConsentChecked] = useState(true);
  const [isUpdatingDigitalConsent, setIsUpdatingDigitalConsent] = useState(false);
  const user = useContext(AuthContext);
  const isLoading = areEmailsLoading || arePhonesLoading;
  const [hasConfirmedEmail, setHasConfirmedEmail] = useState(false);

  const handleShowMoreClick = () => {
    setShouldShowMore(!shouldShowMore);
  };

  const handleLogout = async () => {
    await signOut({ redirect: false });
    window.location.href = `${process.env.NEXT_PUBLIC_STS_URL}/Account/Logout`;
  };

  function handleAcceptance(event, value) {
    setIsTermsAccepted(value);
  }

  function handleDigitalConsentCheck(event, value) {
    setIsDigitalConsentChecked(value);
  }

  async function updateDigitalConsent() {
    if (isDigitalConsentChecked) {
      setIsUpdatingDigitalConsent(true);
      try {
        if (user.settings !== null) {
          user.settings.digitalCollectionAccepted = new Date().toISOString();
        }
        await user.updateDigitalCollectionTerms();
      } catch (error) {
        console.error('Failed to update digital collection terms:', error);
        setError('Villa kom upp við að uppfæra samþykki fyrir stafrænni innheimtu. Vinsamlegast reyndu aftur.');
      } finally {
        setIsUpdatingDigitalConsent(false);
      }
    }
  }

  function handleEmailChange(event) {
    if (checkEvent(event)) return;
    validateEmailAddress(event.target.value);
  }

  function validateEmailAddress(email) {
    const err = validateEmail(email);
    if (err === 1) {
      setEmailErrorText('Vinsamlega skráið netfang');
    } else if (err === 2) {
      setEmailErrorText('Netfang á röngu sniði');
    } else if (err === -1) {
      setEmailWarningText(
        'Þetta er ekki algengur endir á netfangi. Vinsamlegast gakktu úr skugga um að innsláttur sé réttur.'
      );
    } else setEmailWarningText(undefined);
    setIsEmailError(err > 0);
    setEmailAddress(email);
  }

  async function handleConfirmation() {
    if (emailAddress === null) {
      validateEmailAddress(emailAddress);
      return;
    }

    if (phone.number === '') {
      setPhoneErrorText('Vinsamlega skráið símanúmer');
      return;
    }

    if (phone.number !== '' && !isDuplicatePhone(phone.dialCode, phone.number)) {
      createPhone(
        {
          countryCode: phone.dialCode,
          number: phone.number,
          getNotifications: true,
        },
        function () {},
        function () {}
      );
    }
    if (!isDuplicateEmail(emailAddress)) {
      createEmail({ address: emailAddress.trim(), getNotifications: true }, onCreateEmailSuccess, onCreateEmailError);
    } else {
      sendEmailConfirmations(getOldUnconfirmedEmails());
      updateDigitalCollection(isDigitalConsentChecked, onDigitalConsentSuccess, onDigitalConsentError);
    }
    await updateDigitalConsent();
    handleClose(null, 'Accepted', hasConfirmedEmail);
  }

  const onDigitalConsentSuccess = (acceptedDate) => {
    if (acceptedDate === '') {
      handleClose(null, 'Declined', hasConfirmedEmail);
    } else {
      handleClose(null, 'Accepted', hasConfirmedEmail);
    }
  };

  const onDigitalConsentError = () => {
    setDigitalConsentError('Villa kom upp við að staðfesta. Vinsamlegast reyndu aftur.');
  };

  const getOldUnconfirmedEmails = () => {
    return !emails
      ? []
      : _.filter(emails, (x) => {
          return !x.confirmed;
        });
  };

  const onCreateEmailSuccess = async (createdEmail) => {
    const emailsToConfirm = getOldUnconfirmedEmails();
    emailsToConfirm.push(createdEmail);
    sendEmailConfirmations(emailsToConfirm);
    user.refreshSettings();
    handleClose(null, 'Accepted', false);
  };

  const sendEmailConfirmations = async (emailsToConfirm) => {
    emailsToConfirm.forEach((emailToConfirm) => {
      sendEmailConfirmation(
        emailToConfirm.id,
        () => {},
        () => {}
      );
    });
  };

  const onCreateEmailError = () => {
    setError('Villa kom upp við að vista netfang. Vinsamlegast reyndu aftur.');
  };

  function isValidPhoneFormat(numberValue, numberData) {
    // If there's no input or just the country code, it's invalid
    if (!numberValue || numberValue.length <= numberData.dialCode.length) {
      return false;
    }

    return (
      numberData.format.split('.').length - 1 === numberValue.length &&
      numberData.dialCode == numberValue.substring(0, numberData.dialCode.length)
    );
  }

  function handlePhoneChange(value, data) {
    const digits = removeWhitespacesAndDashes(value);
    const numberWithoutDialCode = digits.substring(data.dialCode.length, digits.length);

    const currentPhone = {
      dialCode: data.dialCode || '',
      number: numberWithoutDialCode || '',
      full: digits || '',
    };

    setPhone(currentPhone);

    if (!digits || digits === data.dialCode) {
      setIsPhoneError(true);
      setPhoneErrorText('Vinsamlega skráið símanúmer');
      return;
    }

    if (numberWithoutDialCode !== '') {
      if (isValidPhoneFormat(value, data)) {
        setIsPhoneError(false);
      } else {
        setIsPhoneError(true);
        setPhoneErrorText('Símanúmer er á röngu sniði');
      }
    } else {
      setIsPhoneError(true);
      setPhoneErrorText('Vinsamlega skráið símanúmer');
    }
  }

  const isDuplicateEmail = (emailAddress) => {
    return _.findIndex(emails, { address: emailAddress }) !== -1;
  };

  const isDuplicatePhone = (countryCode, number) => {
    return _.findIndex(phones, { countryCode, number }) !== -1;
  };

  useEffect(() => {
    function onSuccess(data) {
      if (data !== null && data.length > 0) {
        setPhones(data);
        let firstPhone = data[0];
        setPhone({
          dialCode: firstPhone.countryCode,
          number: firstPhone.number,
          full: firstPhone.countryCode + firstPhone.number,
        });
        setIsPhoneError(false);
      } else {
        // When no phone numbers exist, set initial error state
        setPhone({
          dialCode: '',
          number: '',
          full: '',
        });
        setIsPhoneError(true);
        setPhoneErrorText('Vinsamlega skráið símanúmer');
      }
      setArePhonesLoading(false);
    }

    function onError() {
      setError(loadError);
      setArePhonesLoading(false);
    }

    if (isOpen) loadPhones(onSuccess, onError);
  }, [isOpen]);

  useEffect(() => {
    function onSuccess(data) {
      if (data !== null && data.length > 0) {
        setEmails(data);
        let firstEmailWithConfirmed = data.find((email) => email.confirmed);
        if (firstEmailWithConfirmed) {
          setEmailAddress(firstEmailWithConfirmed.address);
          validateEmailAddress(firstEmailWithConfirmed.address);
          setHasConfirmedEmail(true);
        } else {
          let emailToSet = data[0];
          setEmailAddress(emailToSet.address);
          validateEmailAddress(emailToSet.address);
          setHasConfirmedEmail(false);
        }
      }
      setAreEmailsLoading(false);
    }

    function onError() {
      setError(loadError);
      setAreEmailsLoading(false);
    }

    if (isOpen) loadEmails(onSuccess, onError);
  }, [isOpen]);
  return (
    <>
      <Modal onClose={handleClose} open={isOpen} sx={{ overflow: 'scroll' }} disableEscapeKeyDown>
        <Paper sx={{ padding: '40px', maxWidth: '1000px', margin: '60px auto' }}>
          <Grid sx={{ paddingBottom: '15px' }}>
            <DialogTitle
              fontSize="36px"
              fontWeight="bolder"
              id="customized-dialog-title"
              onClose={handleClose}
              showCloseIcon={false}
            >
              {isLoading && (
                <div className={pageHeaderLoaderStyles.animatedBarWrap}>
                  <div className={pageHeaderLoaderStyles.animatedBarLoader}>...</div>
                </div>
              )}
              Samþykkja skilmála
            </DialogTitle>
          </Grid>
          <Divider />
          <DialogContent>
            <Typography fontWeight="bolder" fontSize="18px" sx={{ paddingTop: '5px', paddingBottom: '15px' }}>
              Til að skrá þig inn á vefinn þarftu að samþykkja skilmála vefsins og gefa upp netfang og símanúmer.
            </Typography>

            <FormControl variant="standard" fullWidth error={isEmailError || isPhoneError}>
              <Grid sx={{ m: '-5px 0 5px 0' }}>
                <Grid sx={{ m: '10px 0' }}>
                  <OutlinedInput
                    id="new-email"
                    fullWidth
                    autoFocus
                    placeholder="Skrá netfang*"
                    onChange={handleEmailChange}
                    value={emailAddress ?? ''}
                    error={isEmailError}
                    sx={{ color: 'black', width: '300px', height: '34px', pr: '38px' }}
                  />
                  {isEmailError && (
                    <Grid m="10px 0px" xs={2}>
                      <Typography sx={{ color: '#B30000' }}>{emailErrorText}</Typography>
                    </Grid>
                  )}
                  {!isEmailError && emailWarningText !== undefined && (
                    <Grid m="10px 0px" xs={2}>
                      <Typography color="#024DBC">{emailWarningText}</Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid sx={{ m: '10px 0' }}>
                  <PhoneInput
                    isValid={!isPhoneError}
                    country="is"
                    onChange={handlePhoneChange}
                    preferredCountries={['is', 'no', 'dk', 'se', 'pl', 'cz', 'us']}
                    placeholder="Skrá símanúmer"
                    value={phone.full}
                  />
                  {isPhoneError && (
                    <Grid m="10px 0px" xs={2}>
                      <Typography sx={{ color: '#B30000' }}>{phoneErrorText}</Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid m="15px 0 15px 0px">
                  <Typography
                    fontSize="16px"
                    component="a"
                    href="#"
                    className="greenLink"
                    onClick={handleShowMoreClick}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {shouldShowMore
                      ? 'Loka skilmálum fyrir Mínar síður Motus'
                      : 'Lesa skilmála fyrir Mínar síður Motus'}
                    {!shouldShowMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </Typography>
                  {shouldShowMore ? (
                    <ol>
                      <Typography m="10px 20px 10px -30px" fontWeight="400">
                        <li>
                          Eftirfarandi skilmálar gilda um notkun á greiðendavef Motus og Lögheimtunnar. Með innskráningu
                          á vefinn og notkun hans samþykkir notandinn skilmála þessa.
                        </li>
                        <li>
                          Innskráning fer fram í gegnum Audkenni.is með rafrænum skilríkjum. Þá geta forsvarsmenn
                          fyrirtækja notað Íslykil til að skrá sig inn á vefinn. Notendum er óheimilt að veita öðrum
                          aðgang að vefnum með sínum auðkenningarleiðum og bera ábyrgð á að óviðkomandi aðilar komist
                          ekki yfir þær. Verði notandi var við að óeðlilegar aðgerðir séu framkvæmdar á vefnum í hans
                          nafni ber honum að tilkynna þær tafarlaust til Motus eða Lögheimtunnar.
                        </li>
                        <li>
                          Á vefnum geta greiðendur krafna sem eru til innheimtu hjá Motus og Lögheimtunni m.a. gengið
                          frá greiðslum útistandandi krafna, gert samkomulög um greiðsludreifingu, nálgast upplýsingar
                          um stöðu og komið á framfæri ábendingum eða haft uppi fyrirspurnir með einföldum og öruggum
                          hætti.
                        </li>
                        <li>
                          Greiðslur er hægt að inna af hendi með greiðslu kröfu í netbanka, millifærslu inn á reikning
                          Motus og Lögheimtunnar eða í gegnum greiðsluleiðir á vefnum. Skiptar greiðslur og greiðslur
                          með greiðslukortum hafa í för hafa í för með sér útlagðan kostnað sem leggst við
                          kröfufjárhæðina.
                        </li>
                        <li>
                          Greiðendur bera fulla ábyrgð á öllum þeim aðgerðum sem eru framkvæmdar á greiðendavefnum í
                          þeirra nafni. Samþykki greiðenda á skilmálum þessum jafngildir því að þeir hafi staðfest allar
                          aðgerðir sem framkvæmdar eru á greiðendavefnum með rafrænni undirritun eða öðrum jafn traustum
                          auðkenningarmáta.
                        </li>
                        <li>
                          Greiðendur bera sömuleiðis ábyrgð á að kynna sér áhrif þeirra aðgerða sem þeir framkvæma á
                          vefnum. Motus og Lögheimtan bera ekki ábyrgð á tjóni sem kann að verða vegna aðgerða á vefnum
                          sem stafa af vanþekkingu, misskilnings eða misnotkunar á eðli eða áhrifum aðgerðanna.
                        </li>
                        <li>
                          Motus og Lögheimtan leitast við að hafa þær upplýsingar sem eru aðgengilegar á vefnum ávallt
                          sem réttastar en bera ekki ábyrgð á óþægindum eða tjóni sem orsakast beint eða óbeint af því
                          að þar kunna að vera rangar eða ónákvæmar upplýsingar, vegna bilunar í tækjum eða öðrum búnaði
                          fyrirtækjanna, þjónustuaðila þeirra eða notenda, eða af öðrum orsökum.
                        </li>
                        <li>
                          Svo hægt sé að veita greiðendum þá þjónustu sem er í boði á greiðendavefnum er vinnsla ýmiss
                          konar persónuupplýsinga nauðsynleg. Skráning og vistun á netfangi og símanúmeri er nauðsynleg
                          ef notandi kýs að eiga samskipti við Motus og Lögheimtuna í formi fyrirspurna og athugasemda
                          sem sendar eru af vefsvæðinu. Notandinn veitir þá Motus og Lögheimtunni heimild til að nota
                          þær upplýsingar í samskiptum við félögin vegna þeirra innheimtumála sem eru í gangi hverju
                          sinni. Notendur geta óskað eftir og samþykkt sérstaklega að fá innheimtuviðvaranir, í þeim
                          tilfellum sem Motus sendir þær út fyrir kröfuhafa, og milliinnheimtubréf send á stafrænu
                          formi, þ.e. með tilkynningum í tölvupósti eða með sms skilaboðum. Notandi ber ábyrgð því að
                          tilkynna Motus og Lögheimtunni þær breytingar sem hann gerir á netfangi sínu og símanúmeri.
                          Slíkar breytingar má tilkynna á greiðendavefnum. Framangreindum upplýsingum frá notanda er
                          aldrei deilt með þriðja aðila. Nánari upplýsingar um vinnslu persónuupplýsinga er að finna í
                          persónuverndarstefnu Motus og Lögheimtunnar sem er aðgengileg á heimasíðu.
                        </li>
                      </Typography>
                    </ol>
                  ) : (
                    ''
                  )}
                </Grid>
                <FormControlLabel
                  required
                  control={<Checkbox onChange={handleAcceptance} />}
                  checked={isTermsAccepted}
                  label={<Typography fontWeight="bolder">Já ég samþykki skilmála vefsins</Typography>}
                />
                {!isTermsAccepted && (
                  <Typography sx={{ color: '#B30000', paddingBottom: '10px' }}>
                    Nauðsynlegt er að samþykkja skilmálana til að halda áfram.
                  </Typography>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDigitalConsentChecked}
                      onChange={handleDigitalConsentCheck}
                      sx={{ alignSelf: 'flex-start', marginTop: '-8px' }}
                      disabled={isUpdatingDigitalConsent}
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography component="span" fontWeight="bold">
                        Já, ég samþykki að fá áminningar vegna innheimtumála með stafrænum hætti í stað bréfpósts.
                      </Typography>
                      <Typography component="span" sx={{ paddingBottom: '20px' }}>
                        Við getum sent þér innheimtubréf í tölvupósti eða með SMS í stað bréfpósts. Þannig færðu
                        áminningu vegna skulda sem kunna að koma til innheimtu frá kröfuhöfum í innheimtuþjónustu Motus
                        og Lögheimtunni fyrr en ella og getur brugðist hraðar við.
                      </Typography>
                    </Box>
                  }
                  sx={{ alignItems: 'flex-start' }}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  sx={{ backgroundColor: '#eeeeee', color: '#000000', mr: '10px' }}
                  onClick={handleLogout}
                  placeholder="Skrá farsímanúmer"
                >
                  Hætta við
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirmation}
                  disabled={isEmailError || isPhoneError || !isTermsAccepted}
                >
                  Samþykkja
                </Button>
              </Grid>
              <Grid container spacing={5} sx={{ m: '10px 0' }}></Grid>
              {error && (
                <Grid xs={12}>
                  <Error error={{ message: error }} />
                </Grid>
              )}
            </FormControl>
          </DialogContent>
        </Paper>
      </Modal>
    </>
  );
}

TermsOfServiceDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialEmail: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string.isRequired,
    getNotifications: PropTypes.bool.isRequired,
  }),
};
