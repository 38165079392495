import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { setDigitalCollectionUserPreference } from '../helpers/localStorageHelper';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AuthContext } from 'src/context/AuthProvider';
import TermsOfServiceDialog from 'src/components/Modals/TermsOfServiceDialog';
import DigitalCollectionTermsDialog from 'src/components/Modals/DigitalCollectionTermsDialog';
import ConfirmationDialog from './Modals/ConfirmationDialog';
import EmailNotVerifiedDialog from './Modals/EmailNotVerifiedDialog';

function AuthGuard({ children }) {
  const user = useContext(AuthContext);
  const router = useRouter();
  const [shouldShowTermsOfService, setShouldShowTermsOfService] = useState(false);
  const [shouldShowDigitalCollectionTerms, setShouldShowDigitalCollectionTerms] = useState(false);
  const [shouldShowConfirmationDialog, setShouldShowConfirmationDialog] = useState(false);
  const [shouldShowEmailNotVerifiedDialog, setShouldShowEmailNotVerifiedDialog] = useState(false);
  const [hasConfirmedEmail, setHasConfirmedEmail] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const { data: session } = useSession();

  const handleCloseTermsOfServiceDialog = (event, reason, confirmedEmail) => {
    if (reason && reason !== 'backdropClick') {
      user.updateTermsAndAgreement();
      setShouldShowTermsOfService(false);
      setHasConfirmedEmail(confirmedEmail);
      if (!confirmedEmail) {
        setShouldShowConfirmationDialog(true);
      }
    }
  };

  const handleCloseConfirmationDialog = () => {
    setShouldShowConfirmationDialog(false);
    if (!hasConfirmedEmail) {
      setShouldShowEmailNotVerifiedDialog(true);
    }
  };

  const handleCloseEmailNotVerifiedDialog = () => {
    setShouldShowEmailNotVerifiedDialog(false);
  };

  const handleBackToTermsOfService = () => {
    setShouldShowConfirmationDialog(false);
    setShouldShowTermsOfService(true);
  };

  const handleCloseDigitalCollectionTermsDialog = async (event, reason, acceptedDate) => {
    if (reason && reason !== 'backdropClick') {
      if (user.settings !== null) user.settings.digitalCollectionAccepted = acceptedDate;
      try {
        await user.updateDigitalCollectionTerms();

        setShouldShowDigitalCollectionTerms(false);
        if (reason === 'Accepted') {
          setDigitalCollectionUserPreference(session.user.id, 'yes');
          setShouldShowConfirmEmailInfo(true);
        } else {
          setDigitalCollectionUserPreference(session.user.id, 'no');
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    if (!user.isLoading) {
      if (!user.isLoggedIn) {
        sessionStorage.setItem('redirectUrl', window.location);
        router.push('/');
      } else if (user.needsToAcceptTerms) {
        setShouldShowTermsOfService(true);
      } else if (!user.needsToAcceptTerms && !user.hasDigitalCollectionTerms && user.settings?.hasConfirmedEmail) {
        setShouldShowDigitalCollectionTerms(true);
      } else if (user.hasConfirmedEmail) {
        setShouldShowEmailNotVerifiedDialog(true);
      } else if (
        user.isLoggedIn &&
        !user.needsToAcceptTerms &&
        user.settings &&
        user.settings.existsAsAPayer &&
        user.settings.hasContactInfo &&
        !user.settings.isEverythingVerified &&
        router.asPath !== '/stillingar'
      ) {
        router.push('/stillingar');
      }
    }
  }, [user, router]);

  useEffect(() => {
    if (
      user.settings !== null &&
      user.settings !== undefined &&
      !shouldShowTermsOfService &&
      !shouldShowConfirmationDialog
    ) {
      if (!user.settings?.hasConfirmedEmail) {
        setShouldShowEmailNotVerifiedDialog(true);
      }
    }
  }, [user.settings, shouldShowTermsOfService, shouldShowConfirmationDialog]);
  if (
    user.isLoading ||
    shouldShowTermsOfService ||
    shouldShowDigitalCollectionTerms ||
    shouldShowConfirmationDialog ||
    shouldShowEmailNotVerifiedDialog
  ) {
    return (
      <Box sx={{ background: '#f0f0f0 !important', height: '100%', width: '100%' }}>
        <Box sx={{ position: 'absolute', m: '0px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
          <TermsOfServiceDialog
            handleClose={handleCloseTermsOfServiceDialog}
            isOpen={shouldShowTermsOfService}
            isTermsAccepted={isTermsAccepted}
            setIsTermsAccepted={setIsTermsAccepted}
          />
          <ConfirmationDialog
            handleClose={handleCloseConfirmationDialog}
            isOpen={shouldShowConfirmationDialog}
            handleBack={handleBackToTermsOfService}
          />
          <EmailNotVerifiedDialog
            handleClose={handleCloseEmailNotVerifiedDialog}
            isOpen={shouldShowEmailNotVerifiedDialog}
          />
          <DigitalCollectionTermsDialog
            handleClose={handleCloseDigitalCollectionTermsDialog}
            isOpen={shouldShowDigitalCollectionTerms}
          />
          <Typography sx={{ pt: '45px', transform: 'translate(-35%, -50%)' }} variant="body1">
            Vinn úr auðkenningu...
          </Typography>
        </Box>
      </Box>
    );
  }

  return <React.Fragment>{user.isLoggedIn ? children : null}</React.Fragment>;
}

export default AuthGuard;
