import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  DialogContent,
  Divider,
  Paper,
  Typography,
  Button,
  OutlinedInput,
  Alert,
  Snackbar,
  CircularProgress,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DialogTitle from './DialogTitle';
import { checkEvent } from 'src/helpers/eventHelper';
import { validateEmail } from 'src/helpers/inputValidation';
import { loadEmails, sendEmailConfirmation, createEmail } from 'src/api/payerApi';
import { loadError } from 'src/helpers/defaultErrorMessages';

export default function EmailNotVerifiedDialog({ handleClose, isOpen }) {
  const [emails, setEmails] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  const [emailWarningText, setEmailWarningText] = useState('');
  const [shouldShowEmailInput, setShouldShowEmailInput] = useState(false);
  const [feedback, setFeedback] = useState({ open: false, message: '', severity: 'success' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    function onSuccess(data) {
      if (data !== null && data.length > 0) {
        setEmails(data);
      }
    }
    function onError() {
      console.error(loadError);
    }

    if (isOpen) loadEmails(onSuccess, onError);
  }, [isOpen]);

  function handleEmailChange(event) {
    if (checkEvent(event)) return;
    validateEmailAddress(event.target.value);
  }

  function validateEmailAddress(email) {
    const err = validateEmail(email);
    if (err === 1) {
      setEmailErrorText('Vinsamlega skráið netfang');
      setEmailWarningText('');
    } else if (err === 2) {
      setEmailErrorText('Netfang á röngu sniði');
      setEmailWarningText('');
    } else if (err === -1) {
      setEmailErrorText('');
      setEmailWarningText(
        'Þetta er ekki algengur endir á netfangi. Vinsamlegast gakktu úr skugga um að innsláttur sé réttur.'
      );
    } else {
      setEmailErrorText('');
      setEmailWarningText('');
    }
    setIsEmailError(err > 0);
    setEmailAddress(email);
  }

  const getOldUnconfirmedEmails = () => {
    return !emails ? [] : emails.filter((x) => !x.confirmed);
  };

  const sendEmailConfirmations = async (emailsToConfirm) => {
    setIsSubmitting(true);
    let successCount = 0;
    let failCount = 0;

    for (const emailToConfirm of emailsToConfirm) {
      try {
        await new Promise((resolve, reject) => {
          sendEmailConfirmation(
            emailToConfirm.id,
            () => resolve(),
            () => reject()
          );
        });
        successCount++;
      } catch {
        failCount++;
      }
    }

    setIsSubmitting(false);

    if (failCount === 0) {
      setFeedback({
        open: true,
        message: 'Staðfestingarpóstur hefur verið sendur',
        severity: 'success',
      });
    } else if (successCount === 0) {
      setFeedback({
        open: true,
        message: 'Villa kom upp við að senda staðfestingarpóst',
        severity: 'error',
      });
    } else {
      setFeedback({
        open: true,
        message: `Tókst að senda ${successCount} af ${emailsToConfirm.length} póstum`,
        severity: 'warning',
      });
    }
  };

  const handleResendConfirmationClick = () => {
    const unconfirmedEmails = getOldUnconfirmedEmails();
    const emailsToConfirm = [...unconfirmedEmails];

    const isNewEmail = emails ? !emails.some((email) => email.address === emailAddress) : true;
    if (isNewEmail && emailAddress && !isEmailError) {
      createEmail(
        { address: emailAddress.trim(), getNotifications: true },
        (createdEmail) => {
          emailsToConfirm.push(createdEmail);
          sendEmailConfirmations(emailsToConfirm);
        },
        () => {
          if (unconfirmedEmails.length > 0) {
            sendEmailConfirmations(unconfirmedEmails);
          }
        }
      );
    } else if (emailsToConfirm.length > 0) {
      sendEmailConfirmations(emailsToConfirm);
    }
  };

  const toggleEmailInput = () => {
    setShouldShowEmailInput(!shouldShowEmailInput);
    if (!shouldShowEmailInput) {
      setEmailAddress('');
      setEmailErrorText('');
      setEmailWarningText('');
      setIsEmailError(false);
    }
  };

  return (
    <Modal
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
      open={isOpen}
      sx={{ overflow: 'scroll' }}
      disableEscapeKeyDown
    >
      <Paper
        sx={{
          padding: '40px',
          maxWidth: '1000px',
          width: '100%',
          margin: '60px auto',
          minHeight: '45vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid>
          <DialogTitle
            fontSize="36px"
            fontWeight="bolder"
            id="customized-dialog-title"
            onClose={handleClose}
            showCloseIcon={false}
          >
            Netfang er óstaðfest
          </DialogTitle>
        </Grid>
        <Divider />
        <DialogContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Grid m="10px 0px">
            <Typography variant="h4">
              Við sendum þér tölvupóst á skráð netfang. Vinsamlega opnaðu póstinn og smelltu á hlekkinn í honum til að
              staðfesta. Í framhaldinu er nóg að endurhlaða síðuna og þá verða upplýsingar þínar á vefnum aðgengilegar.
            </Typography>
            <Grid mt="35px">
              <Typography variant="h5" fontWeight="bold" pb="5px">
                Pósturinn ætti að berast á:
              </Typography>
              {getOldUnconfirmedEmails().map((email) => (
                <Typography fontSize="16px" key={email.id} pb="5px">
                  {email.address}
                </Typography>
              ))}
            </Grid>
            <Grid sx={{ m: '5px 0 25px 0' }}>
              <Typography
                fontSize="16px"
                variant="body1"
                className="greenLink"
                color="primary"
                onClick={toggleEmailInput}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                Slóstu inn rangt netfang?
              </Typography>
              {shouldShowEmailInput && (
                <>
                  <OutlinedInput
                    id="new-email"
                    fullWidth
                    autoFocus
                    placeholder="Skrá netfang*"
                    onChange={handleEmailChange}
                    value={emailAddress ?? ''}
                    error={isEmailError}
                    sx={{ color: 'black', width: '300px', height: '34px', pr: '38px', mt: '10px' }}
                  />
                  {isEmailError && emailErrorText && (
                    <Typography sx={{ color: '#B30000', mt: '5px' }}>{emailErrorText}</Typography>
                  )}
                  {!isEmailError && emailWarningText && (
                    <Typography sx={{ color: '#024DBC', mt: '5px' }}>{emailWarningText}</Typography>
                  )}
                </>
              )}
            </Grid>
            <Button
              variant="contained"
              onClick={handleResendConfirmationClick}
              disabled={isSubmitting}
              sx={{
                backgroundColor: '#eeeeee',
                color: '#000000',
                mr: '10px',
                width: '200px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                {isSubmitting ? <CircularProgress size={24} sx={{ color: '#000000' }} /> : 'Endursenda póstinn'}
              </Box>
            </Button>
            <Snackbar
              open={feedback.open}
              autoHideDuration={6000}
              onClose={() => setFeedback((prev) => ({ ...prev, open: false }))}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              sx={{ height: '580px' }}
            >
              <Alert severity={feedback.severity} onClose={() => setFeedback((prev) => ({ ...prev, open: false }))}>
                {feedback.message}
              </Alert>
            </Snackbar>
          </Grid>
        </DialogContent>
      </Paper>
    </Modal>
  );
}

EmailNotVerifiedDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
