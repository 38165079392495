import React from 'react';
import PropTypes from 'prop-types';
import { Modal, DialogContent, Divider, Paper, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DialogTitle from './DialogTitle';

export default function ConfirmationDialog({ handleClose, handleBack, isOpen }) {
  return (
    <Modal
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
      open={isOpen}
      sx={{ overflow: 'scroll' }}
      disableEscapeKeyDown
    >
      <Paper
        sx={{
          padding: '40px',
          maxWidth: '1000px',
          width: '100%',
          margin: '60px auto',
          minHeight: '45vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid>
          <DialogTitle
            fontSize="36px"
            fontWeight="bolder"
            id="customized-dialog-title"
            onClose={handleClose}
            showCloseIcon={false}
          >
            Staðfesta netfang
          </DialogTitle>
        </Grid>
        <Divider />
        <DialogContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Grid mb="50px">
            <Typography variant="h4">
              Við höfum sent þér tölvupóst. Vinsamlega opnaðu póstinn og smelltu á hlekkinn í honum. Þegar þú hefur
              staðfest netfangið getur þú skoðað upplýsingarnar þínar hér á vefnum.
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              sx={{ backgroundColor: '#eeeeee', color: '#000000', mr: '10px' }}
              onClick={handleBack}
              placeholder="Skrá farsímanúmer"
            >
              Til baka
            </Button>
          </Grid>
        </DialogContent>
      </Paper>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.string,
};
