import React, { useState, useEffect } from 'react';
import { useSession, signIn } from 'next-auth/react';
import { loadSettings } from 'src/api/payerApi';
import {
  getDigitalCollectionTerms,
  getTermsAndAgreement,
  setDigitalCollectionTerms,
  setTermsAndAgreement,
  setTemporaryPass,
  removeTerms,
  getTemporaryPass,
} from 'src/helpers/localStorageHelper';
import { setAuthToken } from 'src/api/api';
import { getToday, addDays, convertStrToUtcDate } from 'src/helpers/dateHelper';
import { datadogRum } from '@datadog/browser-rum';

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const { data: session, status: status } = useSession();
  const [settings, setSettings] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [hasTermsAndAgreement, setHasTermsAndAgreement] = useState(false);
  const [hasDigitalCollectionTerms, setHasDigitalCollectionTerms] = useState(false);
  const [isTemporaryPassValid, setIsTemporaryPassValid] = useState(false);

  function updateTermsAndAgreement() {
    setTermsAndAgreement(session.user.id, getToday());
    setHasTermsAndAgreement(getTermsAndAgreement(session.user.id) !== null);
  }
  function updateDigitalCollectionTerms() {
    setDigitalCollectionTerms(session.user.id, getToday());
    setHasDigitalCollectionTerms(getDigitalCollectionTerms(session.user.id) !== null);
  }
  function revokeTerms() {
    removeTerms(session.user.id);
    updateTemporaryPass();
  }

  function updateTemporaryPass() {
    var expiryDate = addDays(getToday(), 1);
    setTemporaryPass(session.user.id, expiryDate);
  }

  function checkTemporaryPass() {
    const temporaryPass = convertStrToUtcDate(getTemporaryPass(session.user.id));
    setIsTemporaryPassValid(temporaryPass !== null && getToday() < temporaryPass);
  }

  function onLoadSettingsSuccess(data) {
    setSettings(data);
  }

  function refreshSettings() {
    loadSettings(onLoadSettingsSuccess, () => {});
  }

  useEffect(() => {
    if (status === 'unauthenticated') {
      setIsInitialized(true);
    }
  }, [status]);

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      signIn('motus-identity-server', { redirect: false });
    }
  }, [session?.error]);

  useEffect(() => {
    if (session?.accessToken) {
      datadogRum.setUser({ id: session.user.id });
      setAuthToken(session.accessToken);
      setIsInitialized(true);
      refreshSettings();
      setHasTermsAndAgreement(getTermsAndAgreement(session.user.id) !== null);
      setHasDigitalCollectionTerms(getDigitalCollectionTerms(session.user.id) !== null);
      checkTemporaryPass();
    }
  }, [session?.accessToken]);
  /** user.name er (yfirleitt?) array sem inniheldur nafn greiðandans tvisvar
   * auk notandanafnsins hans (sem er GUID). Reynum að velja rétt nafn til að birta á vefnum. */
  const tryGetName = (user) => {
    if (!user || !user.name) return undefined;
    if (typeof user.name === 'string') return user.name;
    if (!Array.isArray(user.name)) return undefined;

    const names = user.name.filter((x) => x !== user.username);
    return names.length > 0 ? names[0] : user.name[0];
  };
  return (
    <AuthContext.Provider
      value={{
        isLoading: status === 'loading' || !isInitialized,
        isLoggedIn: status === 'authenticated',
        name: tryGetName(session?.user),
        ssn: session?.user?.ssn,
        needsToAcceptTerms: !hasTermsAndAgreement && !isTemporaryPassValid,
        hasDigitalCollectionTerms: hasDigitalCollectionTerms,
        settings,
        setSettings,
        refreshSettings,
        updateTermsAndAgreement,
        updateDigitalCollectionTerms,
        revokeTerms,
        updateTemporaryPass,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
