'use client';

import { useEffect } from 'react';
import crypto from 'crypto';

const FRONTCHAT_ID = process.env.NEXT_PUBLIC_FRONTCHAT_ID as string;
const FRONTCHAT_SECRET = process.env.NEXT_PUBLIC_FRONTCHAT_SECRET as string;

interface FrontChatUser {
  id: string;
  name: string;
  email: string;
  ssn: string;
}

interface FrontChatCustomFields {
  ssn?: string;
}

interface FrontChatContact {
  email?: string;
  customFields?: FrontChatCustomFields;
}

interface FrontChatOptions {
  chatId: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  useDefaultLauncher: boolean;
  email?: string;
  userId?: string;
  userHash?: string;
  name?: string;
  customFields?: FrontChatCustomFields;
  contact?: FrontChatContact;
}

function createFrontChatVerificationSecret(email: string) {
  const hmac = crypto.createHmac('sha256', FRONTCHAT_SECRET);
  return hmac.update(email).digest('hex');
}

const FrontChat = ({ id, name, email, ssn }: FrontChatUser) => {
  useEffect(() => {
    // Dynamically load the script
    const script = document.createElement('script');
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
    script.async = true;
    script.onload = () => {
      // Initialize the chat widget after the script is loaded
      if (window.FrontChat) {
        const options: FrontChatOptions = {
          useDefaultLauncher: true,
          chatId: FRONTCHAT_ID,
          name: name + ' ' + ssn,
          userId: id,
          userHash: createFrontChatVerificationSecret(id),
          contact: {
            email: email,
            customFields: {
              ssn: ssn,
            },
          },
        };
        window.FrontChat('init', options);
      }
      const iframe = document.getElementById('front-chat-iframe');
      if (iframe) {
        iframe.style.zIndex = '1';
      }
    };
    document.body.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, [id, name, email]);

  return null;
};

export default FrontChat;
